<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-toolbar
            dark>
          <v-card-title>
            <span>Assign room for entrance </span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                  <v-text-field dense outlined v-model="enrollment.building_desc" label="Building description" :error="$v.enrollment.building_desc.$error">
                  </v-text-field>
                  <span class="text-danger" v-if="$v.enrollment.building_desc.$error">This information is required.</span>
              </v-col>
                <v-col cols="12">
                    <v-text-field dense outlined v-model="enrollment.room_desc" label="Room description" :error="$v.enrollment.room_desc.$error"></v-text-field>
                    <span class="text-danger" v-if="$v.enrollment.room_desc.$error">This information is required.</span>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                      text
                      class="cancel-btn"
                      x-large
                      @click="closeDialog">
                  Cancel
              </v-btn>
              <v-btn
                      color="btn btn-primary"
                      dark
                      x-large
                      :loading="loading"
                      @click="save()"
              >
                  Save
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import {required, email, requiredIf} from "vuelidate/lib/validators";

const userEnrollment=new UserEnrollmentService();
export default {
    validations: {
        enrollment:{
            building_desc:{required},
            room_desc:{required},
        }
    },
    data(){
        return{
            dialog:false,
            loading:false,
            enrollment:{
                building_desc:null,
                room_desc:null,
            }
        }
    },
    methods:{
        closeDialog(){
            this.dialog=false;
            this.resetData();
        },
        openDialog(){
            this.dialog=true;
        },
        assignEntranceRoom(enrollment){
            this.enrollmentId=enrollment.id;
            this.enrollment.room_desc=enrollment.room_desc;
            this.enrollment.building_desc=enrollment.building_desc;
            this.openDialog();

        },
        resetData(){
            this.$v.$reset();
            this.enrollmentId=null;
            this.enrollment= {
                building_desc:null,
                room_desc:null,
            };
        },
        save(){
            this.$v.$touch()
            if (this.$v.$error) {
                setTimeout(() => {
                    this.$v.$reset()
                }, 3000);
            } else {
                this.loading = true;
                userEnrollment
                    .acceptEnrollmentOffer(this.enrollmentId, this.enrollment)
                    .then((response) => {
                        this.$snotify.success("Assign room successfully");
                        this.closeDialog();
                        this.$emit('refresh');
                    })
                    .catch(err => {

                    })
                    .finally(() => (this.loading = false))
            }
        },
    }
}
</script>