<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage admission applications</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Admission applications
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('pre-registration', 'create')" @click="createNewPreRegistration()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    New application
                  </v-btn>&nbsp;
                  <b-dropdown  v-if="checkIsAccessible('pre-registration', 'export-pre-registration')" size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4" no-caret right no-flip>
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item">
                        <a  @click="createCsv()" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                          <span class="navi-text"> Export</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" md="2">
                  <v-select
                      class="form-control"
                      outlined clearable
                      v-model="search.academic_year"
                      dense v-on:keyup.enter="searchEnrollments()"
                      :items="academicYears"
                      item-text="title"
                      item-value="id"
                      label="Batch">
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Name, Phone, Email"
                    type="text" clearable
                    dense  v-on:keyup.enter="searchEnrollments()"
                    outlined
                    v-model="search.name" @click:clear="clearSearchName()"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Enrolment no"
                    type="text" clearable
                    dense  v-on:keyup.enter="searchEnrollments()"
                    outlined
                    v-model="search.symbol_no"
                  />
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    v-on:keyup.enter="searchEnrollments()"
                    label="Program"
                    :items="programs"
                    item-value="id"
                    item-text="title_text"
                    outlined
                    dense
                    v-model="search.program"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                  <v-col cols="12" md="3">
                      <!-- <input type="text" class="form-control" v-model="search.limit" placeholder="Limit" /> -->
                      <v-select v-on:keyup.enter="searchEnrollments()" label="Application status" outlined dense v-model="search.status" :items="statuses"
                                item-value="value" item-text="title">
                      </v-select>
                  </v-col>

                <v-col cols="12" md="2">
                  <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-on:keyup.enter="searchEnrollments()"
                          v-model="search.start_date"
                          label="Start date"
                          prepend-inner-icon="mdi-calendar"
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        no-title
                        @input="menu2 = false"
                        scrollable
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2">
                  <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="End date"
                          prepend-inner-icon="mdi-calendar"
                          outlined v-on:keyup.enter="searchEnrollments()"
                          dense
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        no-title
                        scrollable
                        @input="menu3 = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select v-on:keyup.enter="searchEnrollments()" label="Entrance Status" outlined dense v-model="search.entrance_status" :items="entrance_status"
                            item-value="value" item-text="title">
                  </v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select v-on:keyup.enter="searchEnrollments()" label="Verification Status" outlined dense v-model="search.is_approved" :items="approved_status"
                            item-value="value" item-text="title">
                  </v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select v-on:keyup.enter="searchEnrollments()" label="Payment Status" outlined dense v-model="search.is_paid" :items="payment_status"
                            item-value="value" item-text="title">
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select v-on:keyup.enter="searchEnrollments()" label="Payment option" outlined dense v-model="search.payment_method" :items="payment_options"
                            item-value="value" item-text="title">
                  </v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select v-on:keyup.enter="searchEnrollments()" label="Result status" outlined dense v-model="search.result_status" :items="result_status"
                            item-value="value" item-text="title">
                  </v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select v-on:keyup.enter="searchEnrollments()" label="Admitted status" outlined dense v-model="search.admitted_status" :items="admitted_status"
                            item-value="value" item-text="title">
                  </v-select>
                </v-col>

                <v-col cols="12" sm="12" md="2" class="text-right">
                  <v-btn @click="searchEnrollments()"
                      class="btn btn-primary btn-search" :loading="loading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>

            </div>
            <div class="row text-left">

              <div class="col-md-6 text-left mb-5" v-if="!loading">
                <strong>Total applications:</strong>
                <span class="badge badge-info text-center">
                {{ total_result }}
                </span>

              </div>
                <div class="col-md-6 text-right">
                    <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4" no-caret right no-flip  v-if="!loading">
                        <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                                <a  @click="manageEntranceExam()" class="navi-link" v-if="checkIsAccessible('entrance-exam', 'list')">
                                <span class="navi-icon">
                                    <i class="fa fa-book-reader"></i>
                                </span>
                                    <span class="navi-text">Manage entrance exam</span>
                                </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="selected_enrolment.length > 0 && checkIsAccessible('printing', 'list')">
                            <a  @click="downloadBulkAdmitCard()" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-print"></i>
                                </span>
                              <span class="navi-text">Download admit card</span>
                            </a>
                          </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="selected_enrolment.length > 0 && checkIsAccessible('printing', 'list')">
                                <a  @click="openPrintDialog('admit_card')" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-print"></i>
                                </span>
                                    <span class="navi-text">Print admit card</span>
                                </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="selected_enrolment.length > 0 && checkIsAccessible('printing', 'list')">
                                <a  @click="openPrintDialog('offer_letter')" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-print"></i>
                                </span>
                                    <span class="navi-text"> Print offer letter</span>
                                </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="selected_enrolment.length > 0 && checkIsAccessible('pre-registration', 'interview-date')">
                                <a  @click="showInterviewDateDialog()" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-calendar-alt"></i>
                                </span>
                                    <span class="navi-text">Set interview date</span>
                                </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="selected_enrolment.length > 0 && checkIsAccessible('entrance-date', 'list')">
                                <a  @click="openEntranceDateDialog()" class="navi-link">
                          <span class="navi-icon">
                            <i class="fa fas fa-bell"></i>
                          </span>
                                    <span class="navi-text">Set entrance date</span>
                                </a>
                            </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                    </b-dropdown>

                </div>
            </div>
            <div class="scroll-x-auto">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th style="width: 40px !important; white-space: pre-wrap;"></th>
                  <th class="px-3" style="width: 250px !important; white-space: pre-wrap;">Registration info</th>
                  <th class="px-3" style="width: 150px !important; white-space: pre-wrap;">Programme</th>
                  <th class="px-3">Submission date</th>
                  <th class="px-3">Entrance date</th>
                  <!--                  <th class="px-3 wrap-column">Payment Status</th>-->
                  <th class="pr-3"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="enrollments.length > 0" v-for="(enrollment, index) of enrollments" :key="index">
                  <td class="px-3 py-3 vertical_align_center" style="width: 40px !important; white-space: pre-wrap;">
                    <input class="checkbox" type="checkbox" v-if="enrollment.is_approved && enrollment.is_paid && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process) "
                           v-model="selected_enrolment" :value="enrollment.id"/>
                      <div class="my-2" v-if="enrollment.user_document_photo" @click="uploadApplicantPhoto(enrollment)" style="cursor: pointer">
                          <img v-if="enrollment.user_document_photo" :src="enrollment.user_document_photo" alt="" class="border rounded" style="height: 60px; max-width: 80px">
                      </div>
                  </td>

                  <td class="px-3 py-3" style="width: 250px !important; white-space: pre-wrap;">
                      <a class="font-weight-bolder text-dark-75 font-size-lg" @click="openDetailDialog(enrollment)">
                        {{ enrollment.full_name }}
                      </a> <br/>
                      <div class="text-secondary"  style=" margin-top:7px">
                          {{ enrollment.symbol_no ? enrollment.symbol_no : "" }}
                      </div>
                      <div class="text-secondary mb-3" style="margin-left: 5px; margin-top:7px">
                      <span  class="text-secondary font-size-md font-weight-medium" v-if="enrollment.phone">{{enrollment.phone!='null' ? enrollment.phone : '' }}
                      </span>
                          <span  class="text-secondary font-size-md font-weight-medium" v-if="enrollment.phone && enrollment.mobile">|
                      </span>
                          <span class="text-secondary font-size-md font-weight-medium" v-if="enrollment.mobile">{{enrollment.mobile!='null' ? enrollment.mobile : '' }}
                      </span>
                      </div>
                      <span v-if="enrollment.submission_type == 'online'"><i class="fa fa-globe text-green mr-2" title="Web application"></i></span>
                      <span v-if="enrollment.submission_type == 'offline'"><i class="fa fa-globe text-blue mr-2" title="Offline submission"></i></span>
                      <span class="text-secondary" v-if="enrollment.is_submitted">
                        <span v-if="enrollment.is_paid">
                            <i class="fa fa-dollar-sign mr-2 text-green" title="Paid"></i>
                        </span>
                        <span v-if="!enrollment.is_paid" ><i class="fa fa-dollar-sign mr-2 text-red" title="Not paid"></i></span>
                      </span>
                      <span v-if="enrollment.is_approved">
                      <i class="fa fa-check-circle mr-2 text-green" title="Information verified"></i>
                    </span>
                      <span v-if="!enrollment.is_approved" >
                        <i class="fa fa-close mr-2 text-danger" title="Information not verified"></i>
                      </span>
                      <span v-if="enrollment.is_approved">
                          <i v-bind:class="{ 'text-green':enrollment.building_desc || enrollment.room_desc,'text-red':!enrollment.building_desc && !enrollment.room_desc}" class="fa fa-building text-green mr-2"
                             :title="!enrollment.building_desc && !enrollment.room_desc ? 'Entrance room not assigned': 'Entrance room  assigned'">
                          </i>
                      </span>
                      <span class="text-secondary" v-if="enrollment.admit_card_printed">
                        <i class="fa fa-print mr-2" title="Admit card printed"></i>
                      </span>
                      <span class="mr-2" v-if="enrollment.formatted_interview_date && enrollment.formatted_interview_time && enrollment.is_approved">
                        <i class="fa fa-calendar-check text-green" :title="'Interview date scheduled - '+enrollment.formatted_interview_date + ' - ' + enrollment.formatted_interview_time"></i>
                      </span>
                      <span class="mr-2" v-if="!enrollment.formatted_interview_date && !enrollment.formatted_interview_time && enrollment.is_approved && enrollment.obtained_marks && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                        <i class="fa fa-calendar-check text-red" title="Interview date not scheduled"></i>
                      </span>
                      <span class="text-secondary" v-if="enrollment.offer_letter_printed">
                        <i class="fa fa-check-circle mr-2" title="Offer letter printed"></i>
                      </span>
                      <span v-if="enrollment.obtained_marks"><i class="fa fa-calculator text-green mr-2" title="Result available"></i></span>
                      <span v-if="enrollment.obtained_marks && enrollment.result_status">
                          <i v-bind:class="{ 'text-green':parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process),'text-red':parseInt(enrollment.obtained_marks) < parseInt(enrollment.eligible_marks_for_process)}"
                             class="fa fa-graduation-cap text-green mr-2"
                             :title="parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process) ? 'Pass': 'Fail'">
                          </i>
                      </span>
                      <span v-if="enrollment.academic_class_title"><i class="fa fa-chalkboard-teacher text-green mr-2" :title="'Academic class assigned -'+ enrollment.academic_class_title"></i></span>
                      <span v-if="!enrollment.academic_class_title && enrollment.obtained_marks && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)"><i class="fa fa-chalkboard-teacher text-red mr-2" title="Academic class not assigned"></i></span>
                      <div class="text-secondary" >
                          <span class="badge badge-warning" v-if="!enrollment.is_submitted">Draft</span>
                      </div>
                  </td>
                  <td class="px-3 py-3" style="width: 150px !important; white-space: pre-wrap;">
                      <span class="font-weight-medium">{{enrollment.program_detail ? enrollment.program_detail.short_title : enrollment.program }}</span>
                  </td>
                  <td class="py-3">
                      <span class="ml-2 font-weight-medium">{{ enrollment.submitted_date }}</span>
                  </td>
                  <td class="wrap-column">
                    <div class="text-secondary">
                      <span class="" v-if="enrollment.entrance_exam_id && enrollment.entrance_date">
                       <span class="font-weight-regular">
                            {{ enrollment.entrance_date }} {{ enrollment.entrance_start_time }}
                        </span>
                        </span>
                    </div>
                    <div class="text-secondary" v-if="enrollment.entrance_exam_id && enrollment.obtained_marks">
                      <span class="">
                        <b>Mark obtained: </b> {{ enrollment.obtained_marks }}
                      </span>
                    </div>
                      <div class="text-secondary" v-if="!enrollment.entrance_exam_id">
                      <span class="">
                       NA
                      </span>
                    </div>
                  </td>
                  <td class="pr-0 text-center">
                    <b-dropdown
                        size="sm"
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i>
                          </span></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover ">
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'send-orientation-email') && enrollment.is_paid && enrollment.enrolment_offer_accepted">
                              <span href="#" class="navi-link" @click="sendOrientationInvitation(enrollment)">
                                  <span class="navi-icon">
                                    <i class="fas fa-envelope"></i>
                                  </span>
                                  <span class="navi-text">Email orientation invitation</span>
                              </span>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'send-enrolment-pin-email') && enrollment.is_paid">
                              <span href="#" class="navi-link" @click="sendEnrolmentPinInMail(enrollment)">
                            <span class="navi-icon">
                              <i class="fas fa-key"></i>
                            </span>
                                  <span class="navi-text">Email enrolment pin</span>
                              </span>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'send-enrolment-symbol-no-email') && enrollment.is_paid">
                              <span href="#" class="navi-link" @click="sendEnrolmentSymbolNumberInMail(enrollment)">
                            <span class="navi-icon">
                              <i class="fas fa-hashtag"></i>
                            </span>
                                  <span class="navi-text">Email enrolment number</span>
                              </span>
                          </b-dropdown-text>
                          <hr v-if="checkIsAccessible('pre-registration', 'send-enrolment-symbol-no-email') && enrollment.is_paid && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)"/>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'offer-letter') && enrollment.entrance_exam_id && enrollment.is_paid &&  parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                          <span href="#" class="navi-link" @click="previewOfferLetter(enrollment)">
                            <span class="navi-icon">
                              <i class="fas fa-print"></i>
                            </span>
                            <span class="navi-text">Print offer letter</span>
                          </span>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'offer-letter') && enrollment.entrance_exam_id && enrollment.is_paid &&  parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                          <span href="#" class="navi-link" @click="previewBackGroundOfferLetter(enrollment)">
                            <span class="navi-icon">
                              <i class="fas fa-envelope"></i>
                            </span>
                            <span class="navi-text">Preview digital offer letter</span>
                          </span>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'offer-letter') && enrollment.entrance_exam_id && enrollment.is_paid && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                          <span href="#" class="navi-link" @click="sendOfferLetterMailWithBackground(enrollment)">
                            <span class="navi-icon">
                              <i class="fas fa-envelope-open"></i>
                            </span>
                            <span class="navi-text">Email offer letter</span>
                          </span>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'offer-letter') && enrollment.entrance_exam_id && !enrollment.is_admitted && enrollment.is_paid && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                                                 <span href="#" class="navi-link" @click="markAsAdmitted(enrollment)">
                                                <span class="navi-icon">
                                                <i class="fas fa-check-circle"></i>
                                              </span>
                                                <span class="navi-text">Mark as admitted</span>
                                                </span>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'offer-letter') && enrollment.entrance_exam_id && enrollment.is_admitted && enrollment.is_paid && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                                                 <span href="#" class="navi-link" @click="unMarkAsAdmitted(enrollment)">
                                                <span class="navi-icon">
                                                <i class="fas fa-times-circle"></i>
                                              </span>
                                                <span class="navi-text">Unmark as admitted</span>
                                                </span>
                        </b-dropdown-text>


                          <hr v-if="checkIsAccessible('pre-registration', 'offer-letter') && enrollment.entrance_exam_id && enrollment.is_paid"/>

                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'preview-pdf') && enrollment.is_paid">
                          <span href="#" class="navi-link"
                                @click="resendAdmitCard(enrollment.id)">
                              <span class="navi-icon">
                                <i class="fas fa-id-badge"></i>
                              </span>
                            <span class="navi-text">Resend admit card</span>
                          </span>
                        </b-dropdown-text>

                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'preview-pdf') && enrollment.entrance_exam_id && enrollment.is_paid">
                          <span href="#" class="navi-link"
                             @click="previewAdmitCard(enrollment)">
                              <span class="navi-icon">
                                <i class="fas fa-id-badge"></i>
                              </span>
                            <span class="navi-text">View admit card</span>
                          </span>
                        </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'preview-invoice') && enrollment.entrance_exam_id && enrollment.is_paid">
                          <span href="#" class="navi-link"
                                @click="previewInvoice(enrollment)">
                              <span class="navi-icon">
                             <i class="fas fa-file-invoice"></i>
                              </span>
                            <span class="navi-text">View invoice</span>
                          </span>
                          </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('pre-registration', 'update-result') && enrollment.is_submitted && enrollment.is_approved && enrollment.entrance_exam_id && enrollment.is_paid && enrollment.can_access_result">
                            <span href="#" class="navi-link"
                               @click="updateUserEnrollmentResult(enrollment,enrollment.obtained_marks)">
                                                              <span class="navi-icon">
                                                                <i class="fas fa-file-alt"></i>
                                                              </span>
                                <span class="navi-text"> Enter result</span>
                            </span>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'interview-date') && enrollment.is_approved && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process) ">
                          <span href="#" class="navi-link" @click="setInterviewDate(enrollment.id)">
                            <span class="navi-icon">
                              <i class="fa fa-calendar-alt"></i>
                            </span>
                            <span class="navi-text">Set interview date</span>
                          </span>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'mark-as-paid') && !enrollment.is_paid && enrollment.is_submitted">
                          <span href="#" class="navi-link"
                             @click="openMarksPaidDialog(enrollment)">
                                                                <span class="navi-icon">
                                                                  <i class="fa fa-dollar-sign"></i>
                                                                </span>
                            <span class="navi-text">Mark as paid</span>
                          </span>
                        </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'offer-letter-visible') && enrollment.entrance_exam_id && enrollment.is_paid && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                              <span href="#" class="navi-link"
                                 @click="offerLetterVisible(enrollment)">
                                  <span class="navi-icon">
                                      <i :class="enrollment.offer_letter_visible ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                                  </span>
                                  <span class="navi-text">{{ enrollment.offer_letter_visible ? 'Hide offer letter from candidate' : 'Show offer letter to candidate' }}</span>
                              </span>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'enrolment-offer-accept') && enrollment.entrance_exam_id && !enrollment.enrolment_offer_accepted && enrollment.is_paid && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                              <span href="#" class="navi-link"
                                 @click="openEnrolmentOfferAcceptDialog(enrollment)">
                                  <span class="navi-icon">
                                      <i class="fas fa-envelope"></i>
                                  </span>
                                  <span class="navi-text">Accept enrolment offer</span>
                              </span>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'enrolment-offer-accept') && enrollment.entrance_exam_id && enrollment.enrolment_offer_accepted && enrollment.is_paid && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                              <span href="#" class="navi-link"
                                 @click="rejectEnrolmentOfferAcceptDialog(enrollment.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-envelope"></i>
                                  </span>
                                  <span class="navi-text">Reject enrolment offer</span>
                              </span>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'assign-class') && enrollment.entrance_exam_id && enrollment.enrolment_offer_accepted && enrollment.is_paid && parseInt(enrollment.obtained_marks) >= parseInt(enrollment.eligible_marks_for_process)">
                              <span href="#" class="navi-link"
                                 @click="assignClass(enrollment.id)">
                                  <span class="navi-icon">
                                      <i class="fas fa-chalkboard"></i>
                                  </span>
                                  <span class="navi-text">Allocate classroom</span>
                              </span>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'assign-entrance-room') && enrollment.entrance_exam_id && !enrollment.is_exam_conducted && enrollment.is_paid && enrollment.is_approved">
                              <span href="#" class="navi-link"
                                    @click="assignEntranceRoom(enrollment)">
                                  <span class="navi-icon">
                                      <i class="fas fa-building"></i>
                                  </span>
                                  <span class="navi-text">Manage entrance room</span>
                              </span>
                          </b-dropdown-text>
                          <hr/>
                          <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('pre-registration', 'upload-document')">
                              <span href="#" class="navi-link"
                                 @click="uploadApplicantPhoto(enrollment)">
                                                                <span class="navi-icon">
                                                                  <i class="fas fa-camera"></i>
                                                                </span>
                                  <span class="navi-text"> Upload applicant photo</span>
                              </span>
                          </b-dropdown-text>


                          <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('pre-registration', 'upload-document')">
                              <span href="#" class="navi-link"
                                 @click="openDocumentUploadDialog(enrollment)">
                                                                <span class="navi-icon">
                                                                  <i class="fas fa-file-alt"></i>
                                                                </span>
                                  <span class="navi-text"> Upload documents</span>
                              </span>
                          </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'edit')">
                          <span href="#" class="navi-link"
                             @click="editEnrolmentApplication(enrollment)">
                                                                <span class="navi-icon">
                                                                  <i class="fas fa-edit"></i>
                                                                </span>
                            <span class="navi-text">Edit application</span>
                          </span>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'preview-pdf') && enrollment.is_submitted">
                            <span href="#" class="navi-link"
                               @click="previewPDF(enrollment.id)">
                          <span class="navi-icon">
                            <i class="fas fa-file-pdf"></i>
                          </span>
                                <span class="navi-text">View summary</span>
                            </span>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'mark-as-submitted') && !enrollment.is_submitted">
                            <span href="#" class="navi-link"
                               @click="markAsSubmitted(enrollment)">
                                                              <span class="navi-icon">
                                                        <i class="fas fa-check-circle"></i>
                                                              </span>
                                <span class="navi-text">Mark as submitted</span>
                            </span>
                        </b-dropdown-text>
                        <hr/>
                        <b-dropdown-text tag="div" class="navi-item" v-if="enrollment.is_submitted && !enrollment.is_approved && checkIsAccessible('pre-registration', 'verify')">
                          <span href="#" class="navi-link"
                             @click="approveEnrollment(enrollment.id)">
                              <span class="navi-icon">
                                <i class="fas fa-check-circle"></i>
                              </span>
                            <span class="navi-text"> Mark as verified</span>
                          </span>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item" v-if="enrollment.is_submitted && enrollment.is_approved && checkIsAccessible('pre-registration', 'verify')">
                          <span href="#" class="navi-link"
                             @click="disapproveEnrollment(enrollment.id)">
                              <span class="navi-icon">
                                <i class="fas fa-circle-xmark"></i>
                              </span>
                            <span class="navi-text"> Mark as not verified</span>
                          </span>
                        </b-dropdown-text>

                        <hr/>
                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('pre-registration', 'delete')">
                          <span href="#" class="navi-link"
                             @click="deletePreRegistration(enrollment.id)">
                                                                <span class="navi-icon">
                                                                  <i class="fas fa-trash"></i>
                                                                </span>
                            <span class="navi-text">  Delete</span>
                          </span>
                        </b-dropdown-text>

                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>

                  </td>
                </tr>
                <tr v-if="enrollments.length === 0">
                  <td colspan="7" class="text-center">
                    <strong>
                      No online application available at the moment to display.
                    </strong>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12 text-right">
                <b-pagination
                        v-if="enrollments.length > 0"
                        class=""
                        @input="getEnrollments"
                        v-model="page"
                        :total-rows="total"
                        :per-page="perPage"
                        first-number
                        last-number
                        :disabled="loading"
                ></b-pagination>
            </div>
          </div>
        </div>
        <detail ref="open-enrollment-detail" @update_enrollment="updateEnrollment"></detail>
        <!--    Import start here-->
        <v-dialog v-model="import_dialog" persistent max-width="600px">
          <v-card>
            <v-toolbar dark>
              <v-card-title class="headline">
                Import students
                <hr>
              </v-card-title>

              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon  @click="import_dialog=false">
                  <i class="fas fa-times"></i>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="import_file" type="file" outlined dense label="Upload csv to import students"
                                :error="$v.import_file.$error"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  ></v-file-input>
                  <span class="text-danger" v-if="$v.import_file.$error">Please select a csv file to import result</span>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btn btn-standard cancel-btn" @click="import_dialog = false" depressed>Cancel</v-btn>
              <v-btn class="btn btn-primary text-white" depressed @click.prevent="importStudents" loading="loading">Import</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--    Import Ends here-->
        <!--    Set interview date-->
        <set-interview ref="set-interview" :dialog="interview_date_dialog"
                       @close_interview_dialog="showInterviewDateDialog"></set-interview>
        <enrolment-admitted ref="enrolment-admitted" @refresh="getEnrollments"></enrolment-admitted>

        <entrance-date ref="set-entrance-date" :dialog="entrance_dialog"
                       :enrollment_id="selected_enrolment"
                       @close_entrance_dialog="openEntranceDateDialog()"></entrance-date>
        <upload-document ref="document-upload" :dialog="document_upload_dialog" :enrollment_id="selected_enrolment_id"
                        @close_document_dialog="openDocumentUploadDialog(null)"></upload-document>
          <assign-entrance-room ref="assign-entrance-room" @refresh="getEnrollments"></assign-entrance-room>
        <upload-applicant-photo
          ref="upload-applicant-photo"
          @refresh="getSetting"
        ></upload-applicant-photo>
        <!--Update Marks-->
        <update-marks ref="update-marks" @refresh="getEnrollments"></update-marks>
        <enrolment-offer-accept ref="enrolment-offer-accept" @refresh="getEnrollments"></enrolment-offer-accept>
        <assign-class  ref="assign-class" @refresh="getEnrollments"></assign-class>
        <print-dialog ref="print-dialog" :dialog="print_dialog" :job_type="job_type" :selected="selected_enrolment" @close_dialog="closePrintDialog()"></print-dialog>
        <!--    set interview date ends here-->
        <v-dialog v-model="mark_paid_dialog" max-width="700">
          <v-card>
            <v-toolbar
                dark>
              <v-card-title>
                <span>Payment option</span>
                <hr>
              </v-card-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="closeMarksPaidDialog">
                  <i class="fa fa-close"></i>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select v-model="mark_as_paid.payment_method" :items="payment_methods" item-value="value" item-text="text"  dense outlined label="Payment options">

                    </v-select>

                  </v-col>
                  <v-col cols="12">
                    <v-menu
                        v-model="paid_date_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="mark_as_paid.paid_date"
                            label="Paid date"
                            prepend-inner-icon="mdi-calendar"
                            readonly dense outlined
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="mark_as_paid.paid_date"
                          @input="paid_date_menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea dense outlined  v-model="mark_as_paid.remarks" label="Remarks"></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  class="cancel-btn"
                  x-large
                  @click="closeMarksPaidDialog">
                Cancel
              </v-btn>
              <v-btn
                  color="btn btn-primary"
                  dark
                  x-large
                  :loading="markPaidloading"
                  @click="markAsPaid()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>
<script>
import UserEnrollmentService from "@/core/services/user/UserEnrollmentService";
import {required} from "vuelidate/lib/validators";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import {API_URL} from "@/core/config";
import UserDocumentService from "@/core/services/user/UserDocumentService";
import AddressService from "@/core/services/user/UserAddressService";
import Detail from "@/view/pages/pre-registration/Detail";
import SetInterview from "@/view/pages/pre-registration/SetInterview";
import EntranceDate from "@/view/pages/pre-registration/EntranceDate";
import UploadDocument from "@/view/pages/pre-registration/UploadDocument";
import PrintDialog from "@/view/pages/pre-registration/PrintDialog";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import UpdateMarks from "@/view/pages/pre-registration/UpdateMarks.vue";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";
import UploadApplicantPhoto from "./UploadApplicantPhoto";
import EnrolmentOfferAccept from "@/view/pages/pre-registration/EnrolmentOfferAccept.vue";
import AssignClass from "@/view/pages/pre-registration/AssignClass.vue";
import AssignEntranceRoom from "@/view/pages/pre-registration/AssignEntranceRoom.vue";
import EnrolmentAdmitted from "@/view/pages/pre-registration/EnrolmentAdmitted.vue";

const siteSetting=new SiteSettingService();
const academicYearService = new AcademicYearService();
const documentService = new UserDocumentService();
const programService = new ProgramService();
const levelService = new LevelService();
const enrollmentService = new UserEnrollmentService();
const addressService = new AddressService();



export default {
  components: {
    UpdateMarks,
    Detail, SetInterview, EntranceDate, UploadDocument, PrintDialog, UploadApplicantPhoto,EnrolmentOfferAccept,AssignClass,AssignEntranceRoom,EnrolmentAdmitted
  },
  validations: {
    import_file: {required}
  },

  data() {
    return {
      markPaidloading:false,
      mark_paid_dialog:false,
      setting:null,
      total_result: 0,
      print_dialog: false,
      interview_date_dialog: false,
      entrance_dialog: false,
      document_upload_dialog: false,
      edit_enrollment_dialog: false,
      attrs: {},
      selected_enrolment: [],
      search: {
        name:'',
        limit: 10,
        program: "",
        status: "complete",
        start_date: "",
        end_date: "",
        type: "pre_registration",
        academic_year:null,
        is_paid:'all',
        entrance_status:'all',
        result_status:'all',
        payment_method:'all',
        is_approved:'all',
        admitted_status:'all',
        symbol_no:null,
      },
      academicYears:[],
      loading: true,
      menu2: false,
      menu3: false,
      import_file: null,
      selected_enrolment_id: null,
      programs: [],
      statuses: [
        {title: 'All', value: 'all'},
        {title: 'Complete', value: 'complete'},
        {title: 'Draft', value: 'draft'},
      ],
      payment_options: [
        {title: 'All', value: 'all'},
        {title: 'Cash at counter', value: 'offline'},
        {title: 'eSewa', value: 'esewa'},
        {title: 'Khati', value: 'khati'},
      ],

      entrance_status: [
        {title: 'All', value: 'all'},
        {title: 'Scheduled', value: 'scheduled'},
        {title: 'Not scheduled', value: 'not_scheduled'},
      ],
      approved_status: [
        {title: 'All', value: 'all'},
        {title: 'Verified', value: 1},
        {title: 'Not verified', value: 0},
      ],
      result_status: [
          {title: 'All', value: 'all'},
          {title: 'Published', value: 1},
          {title: 'Not published', value: 0},
      ],
      admitted_status: [
          {title: 'All', value: 'all'},
          {title: 'Yes', value: 1},
          {title: 'No', value: 0},
      ],
      payment_status: [
          {title: 'All', value:'all'},
          {title: 'Paid', value:1},
          {title: 'Not paid', value:0},
      ],
      closeOnClick: true,
      enrollments: [],
      documents: [],
      cadidateAddresses: [],
      enrollment: {},
      enrollment_details: {},
      dialog: false,
      import_dialog: false,
      enrollment_id: null,
      page: null,
      total: null,
      perPage: null,
      program_id: null,
      level_id: null,
      submitted_date: null,
      is_loading: false,
      job_type:null,
      mark_as_paid:{
        id:null,
        payment_method:'esewa',
        paid_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          remarks:null,
      },
      paid_date_menu:false,
      payment_methods:[
        {'text':'Cash at counter',value:'offline'},
        {'text':'eSewa',value:'esewa'},
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
    isAdmin() {
      return this.user.type === "admin" ? true : false;
    }
  },
  mounted() {
    this.getAcademicYears();
    this.getSetting();


    this.getPrograms();

    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Enrolments", route: "wizard-1"},
      {title: "My Enrolment"}
    ]);


  },
  methods: {
    getSetting() {
      siteSetting.show().then(response => {
        this.setting = response.data;
        if(this.setting && this.setting.enrollment_year_id){
          this.search.academic_year=this.setting.enrollment_year_id;
        }else{
          this.search.academic_year=null;
        }
        this.getEnrollments();
      });
    },
    updateUserEnrollmentResult(enrollment,obtainedMark){
      this.$refs['update-marks'].updateUserEnrollmentResult(enrollment,obtainedMark)
    },
    clearSearchName(){
      this.search.name=null;

    },
    searchEnrollments(){
      this.page=1;
      this.getEnrollments();
    },
    getEnrollments() {
      this.loading = true;
      enrollmentService
          .paginate(this.search, this.page)
          .then(response => {
            this.enrollments = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = parseInt(response.data.meta.per_page);
            this.total_result = response.data.meta.total
          })
          .catch((err) => {
          }).finally(() => {
        this.loading = false
      });
    },
    getAddress() {
      this.is_loading = true;
      addressService
          .paginate(this.enrollment.id)
          .then(response => {
            if (response.data.data && response.data.data[0])
              this.enrollment_details.address = response.data.data[0];
            // this.cadidateAddresses = response.data.data;
          })
          .catch(error => {
            //console.log(error);
          }).finally(() => {
        this.is_loading = false;
      });
    },
    getPrograms() {
      programService.all().then(response => {
        this.programs = response.data;
      });
    },
    openDetailDialog(enrollment) {
      this.dialog = true;
      this.$refs["open-enrollment-detail"].enrollmentDetail(enrollment)

    },
    closeDialog() {
      this.dialog = false;
      this.enrollment_id = null;
      this.enrollment = {};
      // this.getEnrollments();
    },
    openOfflinePayment(id) {
      this.enrollment_id = id;
      this.$refs["offline-payment"].showModal();
    },
    closeOfflinePayment() {
      this.enrollment_id = null;
      this.$refs["offline-payment"].hideModal();
      this.getEnrollments();
    },
    editEnrollment(id) {
      this.$router.push({name: "apply-enrolment-update", params: {id: id}});
    },
    // offlinePayement(id) {
    //   this.$router.push({ name: "offline-payement", params: { id: id } });
    // },
    searchEnrollment() {
      enrollmentService.paginate(this.search).then(response => {
        this.enrollments = response.data.data;
      });
    },

    manageEntranceExam(){
      this.$router.push({
        name:'entrance-exam'
      });
    },
    createCsv() {
      let url = "";
      if (
          this.search != null &&
          this.search != undefined &&
          this.search != ""
      ) {
        var queryString = Object.keys(this.search)
            .map(key => {
              return (
                  encodeURIComponent(key) +
                  "=" +
                  encodeURIComponent(this.search[key])
              );
            })
            .join("&");
        if (queryString) {
          url = url + "?" + queryString;
        }
      }
      window.open(
          API_URL + "user/preregistration-export-csv" + url,
          "_blank"
      );
    },
    deletePreRegistration(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            enrollmentService.delete(id).then(response => {
              this.getEnrollments();
              this.$snotify.success("Entry has been Information deleted")
            }).catch(error => {
              this.$snotify.error("Something went wrong. Please try again later")
            })
          }
        }
      });
    },
    openImportStudentsDialog() {
      this.import_dialog = true
    },
    importStudents() {
        this.loading=true;
        this.$v.import_file.$touch();
        if (this.$v.import_file.$error) {
          setTimeout(() => {
            this.$v.import_file.$reset();
          }, 5000)
        } else {
          let fd = new FormData();
          fd.append('file', this.import_file);
          enrollmentService.importStudents(fd).then(response => {
            this.import_dialog = false
            this.import_file = null;
            this.$v.import_file.$reset();
            this.getEnrollments();
            this.$snotify.success("Data imported")
              this.loading=false
            }).catch(error => {
              this.$snotify.error("Invalid file format. Please check file before uploading")
              this.loading=false
            }
          )
      }
    },
      previewAdmitCard(enrollment) {
        let url = `${API_URL}user/pre-registration/${enrollment.id}/preview/admit-card`
        window.open(url, "_blank")
    },
    resendAdmitCard(enrollmentId) {
       enrollmentService
          .resendAdmitCardMail(enrollmentId)
          .then(response => {
            this.getEnrollments();
            this.$snotify.success("Admit card has sent in mail")
          })
          .catch(error => {
            this.$snotify.error("Something went wrong. Please try again later")
          })
          .finally({});
    },
      previewInvoice(enrollment) {
        let url = `${API_URL}user/pre-registration/${enrollment.id}/preview/invoice`
        window.open(url, "_blank")
    },
    previewPDF(enrollmentId){
      enrollmentService
          .previewPDF(enrollmentId)

    },
    previewBackGroundOfferLetter(enrollment){
      if(enrollment.can_view_offer_letter_with_background){
        let url = `${API_URL}user/pre-registration/${enrollment.id}/preview/background/offer-letter`;
        window.open(url, "_blank")
      }else{
        this.$snotify.error("Offer letter not configured")
      }
    },
    sendOfferLetterMailWithBackground(enrollment){
      if(enrollment.can_view_offer_letter_with_background) {
        enrollmentService
            .sendOfferLetterMailWithBackground(enrollment.id)
            .then(response => {
              this.getEnrollments();
              this.$snotify.success("Offer letter has sent in mail")
            })
            .catch(error => {
              this.$snotify.error("Something went wrong. Please try again later")
            })
            .finally({});
      }else{
        this.$snotify.error("Offer letter not configured")
      }
    },
    previewOfferLetter(enrollment) {

      let url = `${API_URL}user/pre-registration/${enrollment.id}/preview/offer-letter`
      window.open(url, "_blank")

    },
    sendEnrolmentPinInMail(enrollment){
        this.$confirm({
            message: `Are you sure you want to send enrolment pin?`,
            button: {
                no: "No",
                yes: "Yes"
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
                if (confirm) {
                    enrollmentService
                        .sendEnrolmentPinInMail(enrollment.id)
                        .then(response => {
                            this.$snotify.success("Enrolment pin has sent in mail")
                        }).catch((err) => {

                        })
                        .finally(() => {

                    });
                }
            }
        });
    },
      sendOrientationInvitation(enrollment){
          if(enrollment.has_orientation_date){
              this.$confirm({
                  message: `Are you sure you want to notify upcoming orientation to candidate ?`,
                  button: {
                      no: "No",
                      yes: "Yes",
                  },
                  callback: (confirm) => {
                      if (confirm) {
                          this.actionloading=true;
                          enrollmentService
                              .sendOrientationInvitation(enrollment.id)
                              .then((response) => {
                                  this.$snotify.success("Orientation invitation sent successfully via email");
                                  this.$emit('refresh-entrance');
                              })
                              .catch((err) => {
                                  this.$snotify.error(
                                      "Something went wrong. Please try again later!!"
                                  );
                              })
                              .finally(() => {
                                  this.actionloading=false;
                              })
                      }
                  },
              });
          }else{
              this.$snotify.error(
                  "Orientation date has not configured"
              );
          }
      },
      sendEnrolmentSymbolNumberInMail(enrollment){
        this.$confirm({
            message: `Are you sure you want to send enrolment symbol no.?`,
            button: {
                no: "No",
                yes: "Yes"
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
                if (confirm) {
                    enrollmentService
                        .sendEnrolmentSymbolNoInMail(enrollment.id)
                        .then(response => {
                            this.$snotify.success("Enrolment symbol no has sent in mail")
                        }).catch((err) => {

                        })
                        .finally(() => {

                    });
                }
            }
        });
    },
    printOfferLetter() {
      this.$confirm({
        message: `Are you sure you want to print offer letter?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let data = {ids: this.selected_enrolment}
            enrollmentService.printOfferLetter(data).then(response => {
              this.displayMessage("success")
            }).catch(error => {
              this.displayMessage("error")
            })
          }
        }
      });
    },
    closeMarksPaidDialog(){
      this.markPaidloading=false;
      this.mark_paid_dialog=false;
      this.mark_as_paid={
        id:null,
        payment_method:'esewa',
        paid_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          remarks:null,
      };
    },
   openMarksPaidDialog(enrollment){
     this.mark_paid_dialog=true;
     this.mark_as_paid.id=enrollment.id;
    },
    offerLetterVisible(enrollment){
       let  visible_status_message =enrollment.offer_letter_visible ? 'Are you sure you want to make offer letter invisible ? ' :'Are you sure you want to make offer letter visible ?';
       console.log(visible_status_message)
        this.$confirm({
            message:  visible_status_message ,
            button: {
                no: "No",
                yes: "Yes"
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
                if (confirm) {
                    enrollmentService
                        .changeOfferLetterVisible(enrollment.id)
                        .then(response => {
                            this.displayMessage("success")
                            this.getEnrollments();
                        })
                        .catch(error => {
                          this.displayMessage("error")
                      })
                      .finally(() => {

                      });
                }
            }
        });
    },
    openEnrolmentOfferAcceptDialog(enrollment){
      this.$refs['enrolment-offer-accept'].enrolmentOfferAccept(enrollment.id);
    },
    assignClass(enrollmentId){
      this.$refs['assign-class'].assignClass(enrollmentId);
    },
   assignEntranceRoom(enrollment){

        this.$refs['assign-entrance-room'].assignEntranceRoom(enrollment);
    },
    rejectEnrolmentOfferAcceptDialog(enrollmentId){
        this.$confirm({
            message: `Are you sure you want to reject this enrolment offer?`,
            button: {
                no: "No",
                yes: "Yes"
            },
            /**
             * Callback Function
             * @param {Boolean} confirm
             */
            callback: confirm => {
                if (confirm) {
                    let data={
                        'enrolment_offer_accepted':0,
                        'enrolment_offer_accepted_datetime':null,
                    };
                    enrollmentService
                        .acceptEnrollmentOffer(enrollmentId,data)
                        .then(response => {
                            this.displayMessage("success")
                            this.getEnrollments();

                        })
                        .catch(error => {
                            this.displayMessage("error")
                        })
                        .finally(() => {

                        });
                }
            }
        });
    },
    markAsPaid() {
      this.$confirm({
        message: `Are you sure you want to mark payment as complete?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.markPaidloading=true;
            enrollmentService.markAsPaid(this.mark_as_paid.id,this.mark_as_paid)
                .then(response => {
                  this.displayMessage("success")
                  this.getEnrollments();
                  this.closeMarksPaidDialog();
              })
                .catch(error => {
                   this.displayMessage("error")
                 })
                .finally(() => {
              this.markPaidloading = false
            });
          }
        }
      });
    },
    updateEnrollment(enrollment) {
      enrollmentService.updateEnrollmentUser(enrollment.id, enrollment).then(response => {
        this.displayMessage("success")
        this.closeDialog();
      }).catch(error => {
        this.displayMessage("error")
      })
    },
    showInterviewDateDialog() {
      if (this.interview_date_dialog) {
        this.selected_enrolment = [];
        this.getEnrollments();
      } else {
        this.$refs['set-interview'].setSelectedCandidate(this.selected_enrolment)
      }
      this.interview_date_dialog = !this.interview_date_dialog;
    },
    openEntranceDateDialog() {
      if (!this.entrance_dialog) {
        this.entrance_dialog = true
        this.selected_enrolment_id = enrollment.id
        this.$refs['set-entrance-date'].entranceDateAndTimeSet(enrollment.selected_entrance_date, enrollment.selected_entrance_time)
      } else {
        this.selected_enrolment_id = []
        this.selected_enrolment = []
        this.selected_entrance_date = null
        this.selected_entrance_time = null
        this.entrance_dialog = false;
        this.getEnrollments();
      }
    }, openDocumentUploadDialog(enrollment = null) {
      if (!this.document_upload_dialog) {
        this.document_upload_dialog = true
        this.selected_enrolment_id = enrollment.id
      } else {
        this.selected_enrolment_id = null;
        this.document_upload_dialog = false;
        // this.getEnrollments();
      }
    },
    editEnrolmentApplication(enrollment = null) {
        if(enrollment.can_edit_enrollment){
            this.$router.push({
                name:'pre-registration-update',
                params:{enrollmentId:enrollment.id}
            });
        }else{
            this.$snotify.error("Cannot edit this old year enrollment");
        }

    },
    createNewPreRegistration(){
        if(this.setting.enrollment_year_id){
            this.$router.push(
                { name: 'pre-registration-create'}
            )
        }else{
            this.$snotify.error("Please select your Enrolment Year in order to create.");
        }
    },
    markAsSubmitted(enrollment) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.$emit("toogle_overlay");
            enrollmentService.markAsSubmitted(enrollment.id).then(resposnse => {
              this.displayMessage("success")
              this.$emit("toogle_overlay");
              this.getEnrollments();
            }).catch(error => {
              this.$emit("toogle_overlay");
              this.displayMessage("error")
            })
          }
        }
      });

    },
    openPrintDialog(type) {
      if (!this.print_dialog) {
        if(type){
          this.job_type=type;
          this.print_dialog = true;
        }
      } else {
        this.selected_enrolment = []
        this.print_dialog = false;
        this.job_type=null;
      }
    },
    downloadBulkAdmitCard(){
      enrollmentService
          .downloadBulkAdmitCard({'selected_enrolment':this.selected_enrolment});
    },
    closePrintDialog() {
      this.job_type=null;
      this.print_dialog = false;
      this.selected_enrolment = [];
    },
    getAcademicYears() {

      academicYearService
          .paginate()
          .then(response => {
            this.academicYears = response.data.data;
          }).catch(() => {

      });

    },
    approveEnrollment(id){
      this.$confirm({
        message: `Are you sure you want to approve application?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            enrollmentService
            .approveApplication(id)
            .then(response => {
              this.displayMessage("success")
              this.getEnrollments();
            }).catch(error => {
              this.displayMessage("error")
            })
          }
        }
      });
    },
    disapproveEnrollment(id){
      this.$confirm({
        message: `Are you sure you want to cancel verification?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            enrollmentService
            .disapprove(id)
            .then(response => {
              this.displayMessage("success")
              this.getEnrollments();
            }).catch(error => {
              this.displayMessage("error")
            })
          }
        }
      });
    },
    setInterviewDate(id){
      if(id){
        this.selected_enrolment.push(id);
      }
      this.showInterviewDateDialog();
    },

    uploadApplicantPhoto(enrollment){
      this.$refs['upload-applicant-photo'].showModal(enrollment);
    },
    unMarkAsAdmitted(enrollment){
      this.$confirm({
        message: `Are you sure you want to unmark as admitted?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            let data={};
            data.is_admitted=0;
            data.admission_date=null;
            data.admission_remarks=null;
            this.loading = true;
            enrollmentService
                .markEnrollmentAdmitted(enrollment.id,data)
                .then((response) => {
                  this.$snotify.success("Information updated successfully");

                  this.getEnrollments();
                })
                .catch(err => {

                })
                .finally(() => (this.loading = false))
          }
        }
      });

    },
    markAsAdmitted(enrollment){
      this.$refs['enrolment-admitted'].markAsAdmitted(enrollment.id);
    },

  }
}
</script>

<style scoped>
    .wrap-column{
        max-width: 100px !important;
        white-space: pre-wrap;
    }
    .rounded-checkbox {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        border: 2px solid #999;
    }

    .rounded-checkbox:checked {
        background-color: #6FAEEA;
    }
</style>
